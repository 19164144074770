<template>
  <th
    class="is-clickable not-selectable"
    @click="toggleValue"
  >
    <slot />
    <span class="ordering-toggle-icon">
      <i
        v-if="isAscending"
      >
        ▲
      </i>
      <i
        v-if="isDescending"
      >
        ▼
      </i>
    </span>
  </th>
</template>

<script>
import { computed, inject } from 'vue';

export default {
    name: 'OrderingToggle',

    props: {
        field: {
            type: String,
            required: true,
        },
    },

    setup(props) {
        const {
            sorting: {
                changeOrdering,
                directions,
                ordering,
            },
        } = inject('btbTransactions');

        const direction = computed(() => (
            ordering.list[props.field]
        ));

        const isAscending = computed(() => (
            direction.value === directions.ASCENDING
        ));

        const isDescending = computed(() => (
            direction.value === directions.DESCENDING
        ));

        const toggleValue = () => {
            changeOrdering(props.field);
        };

        return {
            direction,
            isAscending,
            isDescending,
            toggleValue,
        };
    },
};
</script>
