<template>
  <tr
    class="is-centered"
  >
    <td>{{ transaction.transactionId }}</td>
    <td v-if="shouldDisplayCardDetails">
      {{ transaction.cardDetails }}
    </td>
    <td v-if="shouldDisplayCardId">
      {{ transaction.cardId }}
    </td>
    <td v-if="shouldDisplayEmail">
      {{ transaction.email }}
    </td>
    <td>{{ formatAmount(transaction.amount, transaction.currency) }}</td>
    <td>{{ dateToUTC(transaction.timestamp) }}</td>
    <td><StatusIcon :status="transaction.status" /></td>
    <td>{{ formatAmount(transaction.processingFee, transaction.targetCurrency) }}</td>
    <td>{{ formatAmount(transaction.enteredAmount, transaction.targetCurrency) }}</td>
  </tr>
</template>

<script>
import StatusIcon from '@/TransactionListCommon/StatusIcon.vue';

export default {
    components: {
        StatusIcon,
    },

    props: {
        transaction: {
            type: Object,
            required: true,
        },
        shouldDisplayCardId: {
            type: Boolean,
            required: true,
        },
        shouldDisplayCardDetails: {
            type: Boolean,
            required: true,
        },
        shouldDisplayEmail: {
            type: Boolean,
            required: true,
        },
    },

    setup() {
        const formatAmount = (amount, curr) => new Intl.NumberFormat(
            'en-US', { style: 'currency', currency: curr },
        ).format(amount / 100);

        const dateToUTC = (date) => date.toUTCString();

        return {
            formatAmount,
            dateToUTC,
        };
    },
};
</script>
