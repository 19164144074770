import { h } from 'vue';

import StatusIcon from '@/TransactionListBtb/StatusIcon.vue';

const formatAmount = (amount, curr, creation) => {
    if (creation === 'REFUND' || creation === 'TRANSFER BACK') {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: curr }).format((amount / 100) * -1);
    }

    return new Intl.NumberFormat('en-US', { style: 'currency', currency: curr }).format(amount / 100);
};

const formatTransactionFee = (amount, curr, creation) => {
    if (amount === '') {
        return '';
    }

    return formatAmount(amount, curr, creation);
};

const dateToCET = (date) => `${date.toLocaleString('en-GB', {
    timeZone: 'CET',
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
})} CET`;

export default ({ flagsmith }) => {
    const allFields = [
        {
            key: 'transactionId',
            label: 'Transaction ID',
        },
        {
            key: 'email',
            label: 'Email',
        },
        {
            key: 'name',
            label: 'Name',
            isVisible: flagsmith.hasFeature('name_surname_btb'),
        },
        {
            key: 'surname',
            label: 'Surname',
            isVisible: flagsmith.hasFeature('name_surname_btb'),
        },
        {
            key: 'settledAmount',
            label: 'Settled Amount',
            formatter: ({ settledAmount, creation }) => formatAmount(settledAmount, 'JPY', creation),
        },
        {
            key: 'transactionRequestDate',
            label: 'Receipt Date',
            formatter: ({ transactionRequestDate, status }) => {
                if (['CREATED', 'INITIALIZED', 'BLOCKED'].includes(status)) {
                    return '';
                }

                return dateToCET(transactionRequestDate);
            },
        },
        {
            key: 'status',
            label: 'Status',
            customElement: ({ status }) => h(StatusIcon, { status }),
        },
        {
            key: 'creation',
            label: 'Creation',
            isVisible: flagsmith.hasFeature('creation_status_btb'),
        },
        {
            key: 'processingFee',
            label: 'Processing Fee',
            formatter: ({ processingFee, currency }) => formatAmount(processingFee, currency, 'SYSTEM'),
        },
        {
            key: 'transactionFee',
            label: 'Transaction Fee',
            formatter: ({ transactionFee, currency }) => formatTransactionFee(transactionFee, currency, 'SYSTEM'),
        },
        {
            key: 'authorizedAmount',
            label: 'Authorized Amount',
            formatter: ({ authorizedAmount, currency, creation }) => (
                formatAmount(authorizedAmount, currency, creation)
            ),
        },
        {
            key: 'accountNumber',
            label: 'Account Number',
        },
        {
            key: 'bankName',
            label: 'Bank Name',
        },
        {
            key: 'channel',
            label: 'Channel',
        },
        {
            key: 'creator',
            label: 'Creator',
        },
        {
            key: 'merchantId',
            label: 'Merchant ID',
        },
        {
            key: 'sourceBankName',
            label: 'Source Bank Name',
            isVisible: flagsmith.hasFeature('btb_source_bank'),
        },
        {
            key: 'nonce',
            label: 'Nonce',
            isVisible: flagsmith.hasFeature('nonce_backoffice_btb'),
        },
    ];

    return allFields.filter(
        ({ isVisible }) => typeof isVisible === 'undefined' || isVisible,
    );
};
