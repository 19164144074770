import { createApp } from 'vue';
import { handleTokenAuthorization, redirectToLoginPage } from '@/authentication';

import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import App from './App.vue';

import '@/styles/index.scss';

handleTokenAuthorization()
    .then(() => {
        const app = createApp(App);

        if (process.env.NODE_ENV === 'production') {
            Sentry.init({
                dsn: process.env.VUE_APP_SENTRY_DSN,
                integrations: [new VueIntegration({ app, attachProps: true, logErrors: true })],
            });
        }

        app.mount('#app');
    })
    .catch(() => {
        redirectToLoginPage();
    });
