<template>
  <thead>
    <tr
      class="is-centered"
    >
      <OrderingToggle
        v-for="{ key, label } in fields"
        :key="key"
        :field="key"
      >
        <span
          :class="classNameForCell({ key, label })"
        >
          {{ label }}
        </span>
      </OrderingToggle>
    </tr>
  </thead>
</template>

<script>
import initializeFlagsmith from '@/flagsmith';

import OrderingToggle from './OrderingToggle.vue';
import getTableFields from './tableFields';

export default {
    name: 'TableHeader',

    components: {
        OrderingToggle,
    },

    async setup() {
        const flagsmith = await initializeFlagsmith();

        const classNameForCell = ({ key, label }) => {
            if (key === 'creation') {
                const mapping = {
                    MODIFIED: 'has-background-warning',
                    SYSTEM: 'has-text-danger-dark',
                    MANUAL: 'has-background-danger has-text-white',
                    AUTO: 'has-background-black has-text-white',
                };

                return mapping[label];
            }
            return 'has-text-black-bis';
        };

        const fields = getTableFields({ flagsmith });

        return {
            fields,
            classNameForCell,
        };
    },
};
</script>
