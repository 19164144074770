import setupFilters from './filters';
import setupSorting from './sorting';
import setupBtbTransactions from './btbTransactions';

export default () => {
    const filters = setupFilters();
    const sorting = setupSorting();
    const btbTransactions = setupBtbTransactions(filters, sorting.orderingList);

    return {
        filters,
        sorting,
        btbTransactions,
    };
};
