<template>
  <div class="field">
    <div class="control">
      <input
        :value="modelValue"
        class="input"
        type="text"
        name="{{ name }}"
        :placeholder="label"
        @input="$emit('update:modelValue', $event.target.value)"
      >
    </div>
  </div>
</template>

<script>

export default {
    name: 'TextInput',

    props: {
        label: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        modelValue: {
            type: String,
            default: '',
        },
    },

    emits: ['update:modelValue'],
};
</script>
