<template>
  <div class="table-container">
    <table class="table is-size-7 is-vcentered is-hoverable is-fullwidth blocked-ip-address-list">
      <OrderableTableHeader />
      <tbody v-if="transactions.length">
        <TransactionRow
          v-for="(transaction, index) in transactions"
          :key="index"
          :transaction="transaction"
        />
      </tbody>
      <tbody v-else>
        <tr>
          <td
            class="has-text-centered is-size-7"
            colspan="100"
          >
            No transactions found
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <Pagination
    v-if="isPaginationDisable && maxPage > 1"
    :max-page="maxPage"
  />
  <DownloadCsvButton :disabled="transactions.length === 0" />
</template>

<script>
import { inject } from 'vue';
import initializeFlagsmith from '@/flagsmith';

import DownloadCsvButton from './DownloadCsvButton.vue';

import OrderableTableHeader from './OrderableTableHeader.vue';
import TransactionRow from './TransactionRow.vue';
import Pagination from './Pagination.vue';

export default {
    components: {
        OrderableTableHeader,
        TransactionRow,
        DownloadCsvButton,
        Pagination,
    },

    async setup() {
        const mockedTransactions = [{
            firstName: 'Kamil',
            surname: 'Kominek',
            transactionId: '1',
            email: 'kamil.kominek@makimo.pl',
            nonce: 'Nonce',
            bankName: 'Makimo',
            ipAddress: '192.169.1.1',
            date: new Date(),
            amount: 100,
            currency: 'USD',
            merchantId: '1',
            mid: '1',
            status: 'CREATED',
            targetCurrency: 'EUR',
            processingFee: 1,
            settledAmount: 12,
            enteredAmount: 10,
            bankCode: '1',
            transactionRequestDate: new Date(),
            authorizedAmount: 20,
            creation: 'MANUAL',
        },
        {
            firstName: 'Dominik',
            surname: 'Bisiakowski',
            transactionId: '2',
            email: 'dominikk@makimo.pl',
            nonce: 'NonceTest',
            bankName: 'Makimo',
            ipAddress: '192.168.2.2',
            settledAmount: 12,
            date: new Date(),
            amount: 200,
            currency: 'USD',
            merchantId: '2',
            mid: '2',
            status: 'CREATED',
            targetCurrency: 'EUR',
            processingFee: 1,
            enteredAmount: 10,
            bankCode: '1',
            transactionRequestDate: new Date(),
            authorizedAmount: 20,
            creation: 'MODIFIED',
        },
        {
            firstName: 'Jan',
            surname: 'Nowak',
            transactionId: '0915b189-6f0b-4571-94db-6e6d7ae2b375',
            email: 'kamil.kominek@makimo.pl',
            nonce: 'NonceTest',
            bankName: 'Makimo',
            ipAddress: '192.169.1.1',
            settledAmount: 12,
            date: new Date(),
            amount: 100,
            currency: 'USD',
            merchantId: '1',
            mid: '1',
            status: 'RECEIVED',
            targetCurrency: 'EUR',
            processingFee: 1,
            enteredAmount: 10,
            bankCode: '1',
            transactionRequestDate: new Date(),
            authorizedAmount: 20,
            creation: 'SYSTEM',
        }];

        const {
            btbTransactions: {
                maxPage,
                transactions,
                fetchTransactions,
            },
        } = inject('btbTransactions');

        const flagsmith = await initializeFlagsmith();
        const isMockTransactionsDisable = flagsmith.hasFeature('use_btb_mock_transactions');
        const isPaginationDisable = flagsmith.hasFeature('btb_pagination');

        if (!isMockTransactionsDisable) {
            fetchTransactions();
        } else {
            transactions.value = mockedTransactions;
        }

        return {
            transactions,
            isPaginationDisable,
            maxPage,
        };
    },
};
</script>
