<template>
  <div class="columns is-mobile is-multiline">
    <TextInput
      v-model="filters.transactionId"
      class="column is-full-mobile is-4-tablet pl-1 pr-1 mb-0"
      label="Transaction ID"
      name="transaction-id"
    />
    <TextInput
      v-model="filters.currency"
      class="column is-half-mobile is-4-tablet pl-1 pr-1 mb-0"
      label="Currency"
      name="currency"
    />
    <StatusList
      v-model:status="filters.status"
      class="column is-half-mobile is-4-tablet pl-1 pr-1 mb-0"
      label="Status"
    />
    <TextInput
      v-model="filters.email"
      class="column is-full-mobile is-4-tablet pl-1 pr-1 mb-0"
      label="Email"
      name="email"
    />
    <TextInput
      v-if="shouldDisplayNameSurnameFields"
      v-model="filters.name"
      class="column is-full-mobile is-4-tablet pl-1 pr-1 mb-0"
      label="Name"
      name="name"
    />
    <TextInput
      v-if="shouldDisplayNameSurnameFields"
      v-model="filters.surname"
      class="column is-full-mobile is-4-tablet pl-1 pr-1 mb-0"
      label="Surname"
      name="surname"
    />
    <TextInput
      v-model="filters.accountNumber"
      class="column is-full-mobile pl-1 pr-1 mb-0"
      :class="accountNumberClass"
      label="Account number"
      name="account-number"
    />
    <TextInput
      v-if="shouldDisplayNonce"
      v-model="filters.nonce"
      class="column is-full-mobile is-4-tablet pl-1 pr-1 mb-0"
      label="Nonce"
      name="nonce"
    />
    <CreationList
      v-if="shouldDisplayCreation"
      v-model:status="filters.creation"
      class="column is-full-mobile is-4-tablet pl-1 pr-1 mb-0"
      label="creation"
    />
    <TextInput
      v-model="filters.bankName"
      class="column is-full-mobile is-4-tablet pl-1 pr-1 mb-0"
      label="Bank name"
      name="bank-name"
    />
    <TextInput
      v-model="filters.channel"
      class="column is-full-mobile pl-1 pr-1 mb-0"
      :class="channelClass"
      label="Channel"
      name="channel"
    />
    <TextInput
      v-if="shouldDisplaySourceBank"
      v-model="filters.sourceBankName"
      class="column is-full-mobile is-2-tablet pl-1 pr-1 mb-0"
      label="Source Bank Name"
      name="source-bank-name"
    />
    <MidList
      v-model:status="filters.mid"
      class="column is-full-mobile is-4-tablet pl-1 pr-1 mb-0"
      label="Mid"
    />
    <CalendarInput
      v-model:startDate="filters.dateFrom"
      v-model:endDate="filters.dateTo"
      class="column is-full-mobile is-half-tablet pl-1 pr-1 mb-0"
      label="Creation Date"
    />
    <CalendarInput
      v-if="shouldDisplayReceiptCalendar"
      v-model:startDate="filters.receiptDateFrom"
      v-model:endDate="filters.receiptDateTo"
      class="column is-full-mobile is-half-tablet pl-1 pr-1 mb-0"
      label="Receipt Date"
    />
    <TextInput
      v-model="filters.amountFrom"
      class="column is-full-mobile is-3-tablet pl-1 pr-1 mb-0"
      label="Settled Amount From"
      name="settled-amount-from"
    />
    <TextInput
      v-model="filters.amountTo"
      class="column is-full-mobile is-3-tablet pl-1 pr-1 mb-0"
      label="Settled Amount To"
      name="settled-amount-to"
    />
    <TextInput
      v-model="filters.authorizedAmountFrom"
      class="column is-full-mobile is-3-tablet pl-1 pr-1 mb-0"
      label="Authorized Amount From"
      name="authorized-amount-from"
    />
    <TextInput
      v-model="filters.authorizedAmountTo"
      class="column is-full-mobile is-3-tablet pl-1 pr-1 mb-0"
      label="Authorized Amount To"
      name="authorized-amount-to"
    />
  </div>
</template>

<script>
import { computed, inject } from 'vue';
import initializeFlagsmith from '@/flagsmith';

import CalendarInput from '@/FilterFormCommon/CalendarInput.vue';
import TextInput from '@/FilterFormCommon/TextInput.vue';
import StatusList from './StatusListBtb.vue';
import CreationList from './CreationList.vue';
import MidList from './MidListBtb.vue';

export default {
    name: 'FilterFormBTB',
    components: {
        CalendarInput,
        TextInput,
        StatusList,
        CreationList,
        MidList,
    },
    async setup() {
        const { filters } = inject('btbTransactions');

        const flagsmith = await initializeFlagsmith();

        const shouldDisplaySourceBank = flagsmith.hasFeature('btb_source_bank');
        const shouldDisplayNonce = flagsmith.hasFeature('nonce_backoffice_btb');
        const shouldDisplayCreation = flagsmith.hasFeature('creation_status_btb');
        const shouldDisplayNameSurnameFields = flagsmith.hasFeature('name_surname_btb');
        const shouldDisplayReceiptCalendar = flagsmith.hasFeature('receipt_date_calendar');

        const channelClass = computed(() => (shouldDisplaySourceBank ? 'is-2-tablet' : 'is-4-tablet'));
        const accountNumberClass = computed(() => (shouldDisplayNonce ? 'is-4-tablet' : 'is-8-tablet'));

        return {
            accountNumberClass,
            channelClass,
            filters,
            shouldDisplayCreation,
            shouldDisplayNameSurnameFields,
            shouldDisplayNonce,
            shouldDisplaySourceBank,
            shouldDisplayReceiptCalendar,
        };
    },
};
</script>
