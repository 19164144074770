<template>
  <span
    class="icon"
    :class="{
      'has-text-success': isSuccessfull,
      'has-text-info': isRefund,
      'has-text-danger': isFailed || isUnauthorized,
      'has-text-warning': isUnknown,
    }"
  >
    <i
      class="fas fa-lg"
      :class="{
        'fa-check-circle': isSuccessfull,
        'fa-minus-circle': isFailed,
        'fa-arrow-alt-circle-left': isRefund,
        'fa-question-circle': isUnknown,
        'fa-key': isUnauthorized,
      }"
      :title="status"
    />
  </span>
</template>

<script>
const SUCCESS = 'ACCEPTED';
const REJECTED = 'REJECTED';
const REFUND = 'REFUND';
const FAILED = 'FAILED';
const UNAUTHORIZED = 'FAILED_WITH_UNAUTHORIZED_DATA';

export default {
    props: {
        status: {
            type: String,
            required: true,
        },
    },

    computed: {
        isSuccessfull() {
            return this.status === SUCCESS;
        },

        isFailed() {
            return this.status === REJECTED || this.status === FAILED;
        },

        isRefund() {
            return this.status === REFUND;
        },

        isUnauthorized() {
            return this.status === UNAUTHORIZED;
        },

        isUnknown() {
            return !this.isSuccessfull && !this.isFailed && !this.isRefund && !this.isUnauthorized;
        },
    },
};
</script>
