<template>
  <tr
    class="is-centered"
  >
    <td
      v-for="{ key, formatter, customElement } in fields"
      :key="key"
    >
      <component
        :is="customElement(transaction)"
        v-if="customElement"
      />
      <template v-else>
        <span
          :class="classNameForCell({ key, value: transaction[key] })"
          class="p-1"
        >
          {{ formatter ? formatter(transaction) : transaction[key] }}
        </span>
      </template>
    </td>
  </tr>
</template>

<script>
import initializeFlagsmith from '@/flagsmith';

import getTableFields from './tableFields';

export default {
    name: 'TransactionRow',

    props: {
        transaction: {
            type: Object,
            required: true,
        },
    },

    async setup() {
        const flagsmith = await initializeFlagsmith();

        const classNameForCell = ({ key, value }) => {
            if (key === 'creation') {
                const mapping = {
                    MODIFIED: 'has-background-warning',
                    SYSTEM: 'has-text-danger-dark',
                    MANUAL: 'has-background-danger has-text-white',
                    AUTO: 'has-background-black has-text-white',
                    REFUND: 'has-background-info has-text-white',
                    'TRANSFER BACK': 'has-text-info',
                };

                return mapping[value];
            }
            return 'has-text-black-bis';
        };

        const fields = getTableFields({ flagsmith });

        return {
            fields,
            classNameForCell,
        };
    },
};
</script>
