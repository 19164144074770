<template>
  <thead>
    <tr
      class="is-centered"
    >
      <OrderingToggle
        field="transactionId"
      >
        Transaction ID
      </OrderingToggle>
      <OrderingToggle
        v-if="shouldDisplayCardDetails"
        field="cardDetails"
      >
        Card Details
      </OrderingToggle>
      <OrderingToggle
        v-if="shouldDisplayCardId"
        field="cardId"
      >
        Card ID
      </OrderingToggle>
      <OrderingToggle
        v-if="shouldDisplayEmail"
        field="email"
      >
        Email
      </OrderingToggle>
      <OrderingToggle
        field="amount"
      >
        Settled Amount
      </OrderingToggle>
      <OrderingToggle
        field="timestamp"
      >
        Date
      </OrderingToggle>
      <OrderingToggle
        field="status"
      >
        Status
      </OrderingToggle>
      <OrderingToggle
        field="processingFee"
      >
        Processing Fee
      </OrderingToggle>
      <OrderingToggle
        field="targetCurrency"
      >
        Authorized Amount
      </OrderingToggle>
    </tr>
  </thead>
</template>

<script>
import OrderingToggle from './OrderingToggle.vue';

export default {
    name: 'OrderableTableHeader',

    components: {
        OrderingToggle,
    },

    props: {
        shouldDisplayCardId: {
            type: Boolean,
            required: true,
        },
        shouldDisplayCardDetails: {
            type: Boolean,
            required: true,
        },
        shouldDisplayEmail: {
            type: Boolean,
            required: true,
        },
    },
};
</script>
