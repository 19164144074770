import { reactive, computed } from 'vue';

const directions = {
    ASCENDING: Symbol('ASCENDING'),
    DESCENDING: Symbol('DESCENDING'),
};

function getNextOrdering(currentOrdering) {
    switch (currentOrdering) {
    case directions.ASCENDING:
        return directions.DESCENDING;
    case directions.DESCENDING:
        return null;
    case null:
    default:
        return directions.ASCENDING;
    }
}

export default () => {
    const ordering = reactive({
        list: {},
    });

    const camelToSnake = (key) => key
        .replace(
            /([a-z])([A-Z])/g,
            (
                _match,
                previousWordLastLetter,
                nextWordFirstLetter,
            ) => `${previousWordLastLetter}_${nextWordFirstLetter.toLowerCase()}`,
        );

    const toParameter = ([column, direction]) => (
        `${direction === directions.DESCENDING ? '-' : ''}${camelToSnake(column)}`
    );

    const orderingList = computed(
        () => Object.entries(ordering.list).map(toParameter).join(','),
    );

    const changeOrdering = (column) => {
        const currentOrdering = ordering.list[column];

        ordering.list[column] = getNextOrdering(currentOrdering);
    };

    return {
        orderingList,
        directions,
        changeOrdering,
        ordering,
    };
};
