<template>
  <span
    v-if="isReceived"
    class="icon has-text-success"
  >
    <i
      v-if="isReceived"
      class="fas fa-lg fa-check-circle"
    />
  </span>

  <span
    v-else-if="isCreated"
    class="icon has-text-warning"
  >
    <i
      class="fas fa-lg fa-arrow-circle-right"
      :title="status"
    />
  </span>

  <span
    v-if="isInitialized"
    class="icon has-text-info"
  >
    <i
      class="fas fa-lg fa-arrow-circle-right"
      :title="status"
    />
  </span>

  <span
    v-if="isBlocked"
    class="icon has-text-danger"
  >
    <i
      class="fas fa-lg fa-ban"
      :title="status"
    />
  </span>
</template>

<script>
const CREATED = 'CREATED';
const RECEIVED = 'RECEIVED';
const INITIALIZED = 'INITIALIZED';
const BLOCKED = 'BLOCKED';
export default {
    props: {
        status: {
            type: String,
            required: true,
        },
    },
    computed: {
        isCreated() {
            return this.status === CREATED;
        },
        isReceived() {
            return this.status === RECEIVED;
        },
        isInitialized() {
            return this.status === INITIALIZED;
        },
        isBlocked() {
            return this.status === BLOCKED;
        },
    },
};
</script>
