<template>
  <div>
    <div class="select is-fullwidth">
      <div class="control is-expanded">
        <select
          @change="$emit('update:status', $event.target.value)"
        >
          <option
            value=""
            selected
          >
            All
          </option>
          <option
            value="BLOCKED"
          >
            Blocked
          </option>
          <option value="CREATED">
            Created
          </option>
          <option
            v-if="shouldDisplayInitializedStatus"
            value="INITIALIZED"
          >
            Initialized
          </option>
          <option value="RECEIVED">
            Received
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import initializeFlagsmith from '../flagsmith';

export default {
    emits: ['update:status'],

    async setup() {
        const flagsmith = await initializeFlagsmith();

        const shouldDisplayInitializedStatus = flagsmith.hasFeature('btb_initialize_transaction');

        return {
            shouldDisplayInitializedStatus,
        };
    },
};
</script>
