<template>
  <div class="field range-input">
    <label class="label is-small">{{ label }}</label>
    <div class="control mx-2">
      <input
        ref="amountFromInput"
        name="amountFrom"
        type="range"
        min="0.00"
        max="850.00"
        step="1"
        :value="amountFrom"
      >
      <input
        ref="amountToInput"
        name="amountTo"
        type="range"
        min="0.00"
        max="850.00"
        step="1"
        :value="amountTo"
      >
    </div>
  </div>
</template>

<script>
import JSR from 'mm-jsr';
import { ref, onMounted, onBeforeUnmount } from 'vue';

import debounce from '@/debounce';

export default {
    name: 'RangeInput',

    props: {
        label: {
            type: String,
            required: true,
        },
        amountFrom: {
            type: String,
            required: true,
        },
        amountTo: {
            type: String,
            required: true,
        },
    },

    emits: ['update:amountFrom', 'update:amountTo'],

    setup(props, { emit }) {
        const amountFromInput = ref(null);
        const amountToInput = ref(null);

        const onAmountFromChange = debounce(
            (newValue) => {
                emit('update:amountFrom', `${newValue}00`);
            },
            500,
        );

        const onAmountToChange = debounce(
            (newValue) => {
                emit('update:amountTo', `${newValue}00`);
            },
            500,
        );

        onMounted(() => {
            const slider = new JSR([
                amountFromInput.value,
                amountToInput.value,
            ], {
                sliders: 2,
                values: [props.amountFrom, props.amountTo],
                min: (Number(props.amountFrom) || 0) / 100,
                max: (Number(props.amountTo) || 0) / 100,
                step: 1,
            });

            slider.addEventListener('update', (input, value) => {
                if (input === amountFromInput.value) {
                    onAmountFromChange(value);
                } else {
                    onAmountToChange(value);
                }
            });
        });

        onBeforeUnmount(() => {
            onAmountFromChange.cancel();
            onAmountToChange.cancel();
        });

        return {
            amountFromInput, amountToInput,
        };
    },
};
</script>
