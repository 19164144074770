export default function debounce(fn, timeout) {
    let debounceTimeout = null;
    const context = this;

    const debouncedCall = (...args) => {
        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(() => fn.apply(context, args), timeout);
    };

    debouncedCall.cancel = () => {
        clearTimeout(debounceTimeout);
    };

    return debouncedCall;
}
