<template>
  <NavBar />
  <section class="section">
    <Suspense>
      <template #default>
        <MainLayout />
      </template>
      <template #fallback>
        <div>Loading...</div>
      </template>
    </Suspense>
  </section>
</template>

<script>
import { provide } from 'vue';

import btbTransactions from '@/btbTransactions';

import MainLayout from './MainLayout.vue';
import NavBar from './NavBar.vue';

export default {
    components: {
        MainLayout,
        NavBar,
    },

    setup() {
        provide('btbTransactions', btbTransactions());
    },
};
</script>
