<template>
  <th
    class="is-clickable"
    @click="toggleValue"
  >
    <slot />
    <span class="ordering-toggle-icon">
      <i
        v-if="isAscending"
      >
        ▲
      </i>
      <i
        v-if="isDescending"
      >
        ▼
      </i>
    </span>
  </th>
</template>

<script>
import { ref, computed, inject } from 'vue';

export default {
    name: 'OrderingToggle',

    props: {
        field: {
            type: String,
            required: true,
        },
    },

    setup(props) {
        const direction = ref(null);
        const {
            sorting: {
                directions,
                sortAscendingBy,
                sortDescendingBy,
                removeSortingBy,
            },
        } = inject('transactions');

        const isAscending = computed(() => (
            direction.value === directions.ASCENDING
        ));

        const isDescending = computed(() => (
            direction.value === directions.DESCENDING
        ));

        const toggleValue = () => {
            if (isAscending.value) {
                direction.value = directions.DESCENDING;
                sortDescendingBy(props.field);
            } else if (isDescending.value) {
                direction.value = null;
                removeSortingBy(props.field);
            } else {
                direction.value = directions.ASCENDING;
                sortAscendingBy(props.field);
            }
        };

        return {
            direction,
            isAscending,
            isDescending,
            toggleValue,
        };
    },
};
</script>
