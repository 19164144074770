import { h } from 'vue';

import FilterFormBtb from '@/FilterFormBtb/FilterFormBtb.vue';
import TransactionListBtb from '@/TransactionListBtb/TransactionListBtb.vue';

export default [
    {
        key: 'btb',
        label: 'BtB PAYIN',
        component: () => h(
            'div',
            {
                class: 'box',
            },
            [
                h(FilterFormBtb),
                h(TransactionListBtb),
            ],
        ),
    },
];
