<template>
  <div>
    <div class="select is-fullwidth">
      <div class="control is-expanded">
        <select
          @change="$emit('update:status', $event.target.value)"
        >
          <option
            value=""
            selected
          >
            All
          </option>
          <option value="ACCEPTED">
            Accepted
          </option>
          <option value="REJECTED">
            Rejected
          </option>
          <option value="FAILED">
            Failed
          </option>
          <option value="FAILED_WITH_UNAUTHORIZED_DATA">
            Unauthorized
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    emits: ['update:status'],
};
</script>
