<template>
  <div>
    <div class="select is-fullwidth">
      <div class="control is-expanded">
        <select
          @change="$emit('update:status', $event.target.value)"
        >
          <option
            value=""
            selected
          >
            All
          </option>
          <option
            v-for="mid in mids"
            :key="mid.mid_id"
            :value="mid.mid_id"
          >
            {{ mid.mid_name }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';

export default {
    emits: ['update:status'],

    async setup() {
        const {
            btbTransactions: {
                fetchMids,
            },
        } = inject('btbTransactions');

        const mids = await fetchMids();

        return { mids };
    },
};
</script>
