import { reactive } from 'vue';
import { DateTime } from 'luxon';

const today = DateTime.now().startOf('day');

const todayEndDate = DateTime.now().endOf('day');

export default () => reactive({
    accountNumber: '',
    bankName: '',
    branchCode: '',
    channel: '',
    creator: '',
    currency: '',
    dateFrom: today,
    dateTo: todayEndDate,
    email: '',
    merchantId: '',
    settledAmountFrom: '0',
    settledAmountTo: '250000000',
    status: '',
    transactionId: '',
    receiptDateFrom: null,
    receiptDateTo: null,
    sourceBankName: '',
    nonce: '',
    name: '',
    surname: '',
    authorizedAmountFrom: '',
    authorizedAmountTo: '',
    mid: '',
});
