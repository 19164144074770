<template>
  <div class="table-container">
    <table class="table is-size-7 is-vcentered is-hoverable is-fullwidth blocked-ip-address-list">
      <OrderableTableHeader
        :should-display-card-id="shouldDisplayCardID"
        :should-display-card-details="shouldDisplayCardDetails"
        :should-display-email="shouldDisplayEmail"
      />
      <tbody>
        <template v-if="transactions.length">
          <TransactionRow
            v-for="transaction in transactions"
            :key="transaction.transactionId"
            :transaction="transaction"
            :should-display-card-id="shouldDisplayCardID"
            :should-display-card-details="shouldDisplayCardDetails"
            :should-display-email="shouldDisplayEmail"
          />
        </template>
        <template v-else>
          <tr>
            <td
              class="has-text-centered is-size-7"
              colspan="100"
            >
              No transactions found
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <DownloadCsvButton :disabled="transactions.length === 0" />
  </div>
</template>

<script>
import { inject } from 'vue';

import DownloadCsvButton from '@/TransactionListCommon/DownloadCsvButton.vue';
import initializeFlagsmith from '@/flagsmith';

import OrderableTableHeader from './OrderableTableHeader.vue';
import TransactionRow from './TransactionRow.vue';

export default {
    components: {
        OrderableTableHeader,
        TransactionRow,
        DownloadCsvButton,
    },

    async setup() {
        const {
            transactions: {
                transactions,
                fetchTransactions,
            },
        } = inject('transactions');

        await fetchTransactions();

        const getCardIdFlag = async () => {
            const flagsmith = await initializeFlagsmith();
            return flagsmith.hasFeature('should_display_card_id');
        };

        const shouldDisplayCardID = await getCardIdFlag();

        const getCardDetailsFlag = async () => {
            const flagsmith = await initializeFlagsmith();
            return flagsmith.hasFeature('should_display_card_details_backoffice');
        };

        const shouldDisplayCardDetails = await getCardDetailsFlag();

        const getEmailFlag = async () => {
            const flagsmith = await initializeFlagsmith();
            return flagsmith.hasFeature('should_display_email_backoffice');
        };

        const shouldDisplayEmail = await getEmailFlag();

        return {
            transactions,
            shouldDisplayCardID,
            shouldDisplayCardDetails,
            shouldDisplayEmail,
        };
    },
};
</script>
