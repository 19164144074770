<template>
  <div class="field">
    <label class="label is-small">{{ label }}</label>
    <div class="control">
      <input
        ref="calendarTrigger"
        class="is-small"
        type="date"
      >
    </div>
  </div>
</template>

<script>
import BulmaCalendar from 'bulma-calendar';
import { DateTime } from 'luxon';
import { ref, onMounted, watch } from 'vue';

import initializeFlagsmith from '../flagsmith';

export default {
    name: 'CalendarInput',

    props: {
        label: {
            type: String,
            required: true,
        },
        startDate: {
            type: Object,
            default: null,
        },
        endDate: {
            type: Object,
            default: null,
        },
    },

    emits: ['update:startDate', 'update:endDate'],

    setup(props, { emit }) {
        const calendarTrigger = ref(null);
        const start = ref(props.startDate);
        const end = ref(props.endDate);

        const dateTimeWithAssumedUTC = (date) => {
            if (date === null) {
                return null;
            }

            date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
            return DateTime.fromISO(date.toISOString(), { setZone: 'UTC' });
        };

        const dateTimeWithAssumedEndUTC = (date) => {
            if (date === null) {
                return null;
            }

            return DateTime.fromISO(date.toISOString(), { setZone: 'UTC' });
        };

        watch(start, (newValue) => {
            emit('update:startDate', newValue);
        });
        watch(end, (newValue) => {
            emit('update:endDate', newValue);
        });

        onMounted(async () => {
            const flagsmith = await initializeFlagsmith();

            const calendar = new BulmaCalendar(calendarTrigger.value, {
                startDate: props.startDate !== null
                    ? new Date(props.startDate.toISO())
                    : null,
                endDate: props.endDate !== null
                    ? new Date(props.endDate.toISO())
                    : null,
                isRange: true,
                showClearButton: flagsmith.hasFeature('receipt_date_calendar'),
            });

            calendar.on('select', (e) => {
                const { data: { startDate, endDate } } = e;
                start.value = startDate || null;
                end.value = endDate || null;

                start.value = dateTimeWithAssumedUTC(start.value);
                end.value = dateTimeWithAssumedEndUTC(end.value);
            });

            calendar.on('clear', () => {
                start.value = null;
                end.value = null;
            });
        });

        return { calendarTrigger, start, end };
    },
};
</script>
