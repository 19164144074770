const saveFile = (name, blob) => {
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.setAttribute('download', name);
    a.click();

    window.URL.revokeObjectURL(url);
    a.remove();
};

export default saveFile;
