<template>
  <div class="tabs">
    <ul>
      <li
        v-for="tab in tabs"
        :key="tab.key"
        :class="getActiveTabClass(tab.key)"
        @click="() => onTabClick(tab)"
      >
        <a>{{ tab.label }}</a>
      </li>
    </ul>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
/* this rule override is used due to <script setup> not accepting the export const variables */

import {
    defineEmit, defineProps, onMounted,
} from 'vue';

import tabs from './panelTabs';

const emit = defineEmit(['tab-change']);
const props = defineProps({
    currentTab: Object,
});

const onTabClick = (newTab) => {
    emit('tab-change', newTab);
};

const getActiveTabClass = (tabKey) => ({
    'is-active': tabKey === props.currentTab?.key,
});

onMounted(() => {
    const [firstTab] = tabs;
    emit('tab-change', firstTab);
});
</script>
