<template>
  <nav
    class="navbar is-primary"
    role="navigation"
  >
    <div class="navbar-brand">
      <a
        class="navbar-item"
        href="/"
      >
        Backoffice Panel
      </a>
    </div>
    <div class="navbar-end">
      <div class="navbar-item">
        <div class="buttons">
          <a
            class="button is-primary"
            @click="logoutAndRedirect"
          >
            <strong>Log out</strong>
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { logoutAndRedirect } from '@/authentication';

export default {
    name: 'NavBar',

    setup() {
        return {
            logoutAndRedirect,
        };
    },
};
</script>
