import flagsmith from 'flagsmith';

const {
    VUE_APP_FLAGSMITH_ENV_ID,
    VUE_APP_FLAGSMITH_API_URL,
} = process.env;

let isInitialized = false;

export default async function initializeFlagsmith() {
    if (isInitialized) {
        return flagsmith;
    }

    await flagsmith.init({
        environmentID: VUE_APP_FLAGSMITH_ENV_ID,
        cacheFlags: false,
        api: VUE_APP_FLAGSMITH_API_URL,
    });

    isInitialized = true;

    return flagsmith;
}
