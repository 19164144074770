/* eslint-disable camelcase */
import { ref, watch } from 'vue';
import { httpGet, getCsv } from '../http';
import initializeFlagsmith from '../flagsmith';

const PATH = 'btb-transactions/';
const MIDS_PATH = 'merchants/mids/';

export default (filters, ordering) => {
    const transactions = ref([]);
    const maxPage = ref(0);
    const currentPage = ref(1);

    const responseToLocalFormat = (res) => res.map(({
        transaction_id,
        email,
        settled_amount,
        date,
        status,
        processing_fee,
        authorized_amount,
        creator,
        currency,
        merchant_id,
        account_number,
        bank_name,
        channel,
        transaction_request_date,
        source_bank_name,
        nonce,
        creation,
        name,
        surname,
        page,
        transaction_fee,
    }) => ({
        transactionId: transaction_id,
        email,
        settledAmount: settled_amount,
        date: new Date(date),
        status,
        processingFee: processing_fee,
        authorizedAmount: authorized_amount,
        creator,
        currency,
        merchantId: merchant_id,
        accountNumber: account_number,
        bankName: bank_name,
        channel,
        transactionRequestDate: new Date(transaction_request_date),
        sourceBankName: source_bank_name,
        nonce,
        creation,
        name,
        surname,
        page,
        transactionFee: transaction_fee,
    }));

    const localToRequestFormat = (({
        transactionId,
        currency,
        status,
        email,
        creator,
        dateFrom,
        dateTo,
        settledAmountFrom,
        settledAmountTo,
        merchantId,
        accountNumber,
        bankName,
        channel,
        transactionRequestDate,
        sourceBankName,
        nonce,
        creation,
        name,
        surname,
        receiptDateFrom,
        receiptDateTo,
        authorizedAmountFrom,
        authorizedAmountTo,
        mid,
    }) => ({
        transaction_id: transactionId,
        currency,
        status,
        email,
        creator,
        date_from: dateFrom !== null ? dateFrom.toISODate() : null,
        date_to: dateTo !== null ? dateTo.toISODate() : null,
        settled_amount_from: (
            settledAmountFrom.trim() !== '' ? Math.round(settledAmountFrom * 100) : ''
        ),
        settled_amount_to: (
            settledAmountTo.trim() !== '' ? Math.round(settledAmountTo * 100) : ''
        ),
        authorized_amount_from: (
            authorizedAmountFrom.trim() !== '' ? Math.round(authorizedAmountFrom * 100) : ''
        ),
        authorized_amount_to: (
            authorizedAmountTo.trim() !== '' ? Math.round(authorizedAmountTo * 100) : ''
        ),
        merchant_id: merchantId,
        account_number: accountNumber,
        bank_name: bankName,
        channel,
        transaction_request_date: transactionRequestDate,
        source_bank_name: sourceBankName,
        nonce,
        creation,
        name,
        surname,
        receipt_date_from: receiptDateFrom !== null ? receiptDateFrom.toISODate() : null,
        receipt_date_to: receiptDateTo !== null ? receiptDateTo.toISODate() : null,
        mid,
    }));

    const fetchTransactions = async (page = 1) => {
        const flagsmith = await initializeFlagsmith();
        const response = await httpGet(
            PATH,
            {
                ...localToRequestFormat(filters),
                ordering: ordering.value,
                page,
            },
        );

        transactions.value = responseToLocalFormat(flagsmith.hasFeature('btb_pagination') ? response.transactions : response);
        maxPage.value = response.pages;
    };

    const fetchTransactionsCsv = async () => {
        try {
            const response = await getCsv(
                PATH,
                {
                    ...localToRequestFormat(filters),
                    ordering: ordering.value,
                },
            );
            return response;
        } catch (error) {
            return error;
        }
    };

    const fetchMids = async () => {
        try {
            const response = await httpGet(MIDS_PATH);

            return response;
        } catch (error) {
            return error;
        }
    };

    watch([filters, ordering], () => {
        currentPage.value = 1;
        fetchTransactions();
    });

    return {
        transactions,
        currentPage,
        maxPage,
        fetchTransactions,
        fetchTransactionsCsv,
        fetchMids,
    };
};
