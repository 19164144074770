<template>
  <button
    class="button is-small is-primary"
    title="Download transactions as CSV file"
    @click="downloadTransactions"
  >
    Download
  </button>
</template>

<script>
import { inject } from 'vue';
import saveFile from '@/TransactionListCommon/saveFile';

export default {
    async setup() {
        const {
            btbTransactions: {
                fetchTransactionsCsv,
            },
        } = inject('btbTransactions');
        const getDate = () => {
            const currentDate = new Date();
            const splitDate = currentDate.toISOString().split('T');
            const date = splitDate[0];
            const time = splitDate[1].split('.')[0].replaceAll(':', '-');
            return `${date}-${time}`;
        };
        const downloadTransactions = async () => {
            const data = await fetchTransactionsCsv();
            const name = `Transactions-${getDate()}.csv`;
            saveFile(name, data);
        };
        return {
            downloadTransactions,
        };
    },
};
</script>
