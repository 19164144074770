<template>
  <div class="container columns is-mobile is-multiline level-right">
    <TextInput
      v-model="filters.transactionId"
      class="column is-full-mobile is-4-tablet pl-1 pr-1 mb-0"
      label="Transaction ID"
      name="transaction-id"
    />
    <TextInput
      v-if="shouldDisplayCardID"
      v-model="filters.cardId"
      :class="cardIdDetailsClass"
      label="Card ID"
      name="card-id"
    />
    <TextInput
      v-if="shouldDisplayCardDetails"
      v-model="filters.cardDetails"
      :class="cardIdDetailsClass"
      label="Card Details"
      name="card-details"
    />
    <TextInput
      v-model="filters.currency"
      class="column is-half-mobile is-4-tablet pl-1 pr-1 mb-0"
      label="Currency"
      name="currency"
    />
    <StatusList
      v-model:status="filters.status"
      :class="statusClass"
      label="Status"
    />
    <TextInput
      v-if="shouldDisplayEmail"
      v-model="filters.email"
      class="column is-half-mobile is-2-tablet pl-1 pr-1 mb-0"
      label="Email"
      name="email"
    />
    <CalendarInput
      v-model:startDate="filters.timestampFrom"
      v-model:endDate="filters.timestampTo"
      class="column is-full-mobile is-6-tablet pl-1 pr-1 mb-0"
      label="Dates"
    />
    <RangeInput
      v-model:amountFrom="filters.amountFrom"
      v-model:amountTo="filters.amountTo"
      class="column is-full-mobile is-full-tablet pl-1 pr-1 mb-0"
      label="Settled Amount"
    />
  </div>
</template>

<script>
import { inject } from 'vue';

import CalendarInput from '@/FilterFormCommon/CalendarInput.vue';
import RangeInput from '@/FilterFormCommon/RangeInput.vue';
import TextInput from '@/FilterFormCommon/TextInput.vue';
import StatusList from '@/FilterFormCommon/StatusList.vue';
import initializeFlagsmith from '@/flagsmith';

export default {
    name: 'FilterForm',
    components: {
        CalendarInput,
        RangeInput,
        TextInput,
        StatusList,
    },
    async setup() {
        const { filters } = inject('transactions');

        const getCardIdFlag = async () => {
            const flagsmith = await initializeFlagsmith();
            return flagsmith.hasFeature('should_display_card_id');
        };

        const shouldDisplayCardID = await getCardIdFlag();

        const getCardDetailsFlag = async () => {
            const flagsmith = await initializeFlagsmith();
            return flagsmith.hasFeature('should_display_card_details_backoffice');
        };

        const shouldDisplayCardDetails = await getCardDetailsFlag();

        const getEmailFlag = async () => {
            const flagsmith = await initializeFlagsmith();
            return flagsmith.hasFeature('should_display_email_backoffice');
        };

        const shouldDisplayEmail = await getEmailFlag();

        const getTabClass = () => {
            if (shouldDisplayCardID && shouldDisplayCardDetails) {
                return 'column is-full-mobile is-2-tablet pl-1 pr-1 mb-0';
            }

            return 'column is-full-mobile is-4-tablet pl-1 pr-1 mb-0';
        };

        const cardIdDetailsClass = getTabClass();

        const getStatusClass = () => {
            if (shouldDisplayEmail) {
                return 'column is-half-mobile is-4-tablet pl-1 pr-1 mb-0';
            }

            return 'column is-half-mobile is-6-tablet pl-1 pr-1 mb-0';
        };

        const statusClass = getStatusClass();

        return {
            filters,
            shouldDisplayCardID,
            shouldDisplayCardDetails,
            shouldDisplayEmail,
            cardIdDetailsClass,
            statusClass,
        };
    },
};
</script>
