<template>
  <div class="columns">
    <div class="column">
      <TabsSwitcher
        v-if="shouldDisplayTabsSwitcher"
        :current-tab="currentTab"
        @tab-change="onTabChange"
      />
      <component
        :is="currentTab?.component"
        v-if="shouldDisplayBtbComponent"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';

import FilterFormCard from '@/FilterFormCard/FilterFormCard.vue';
import TransactionListCard from '@/TransactionListCard/TransactionListCard.vue';

import initializeFlagsmith from '../flagsmith';
import TabsSwitcher from './TabsSwitcher.vue';

export default {
    components: {
        FilterFormCard,
        TabsSwitcher,
        TransactionListCard,
    },

    setup() {
        const currentTab = ref(null);

        const shouldDisplayTabsSwitcher = ref(false);
        const shouldDisplayBtbComponent = ref(false);

        onMounted(async () => {
            const flagsmith = await initializeFlagsmith();

            shouldDisplayTabsSwitcher.value = flagsmith.hasFeature(
                'should_display_tabs_switcher',
            );

            shouldDisplayBtbComponent.value = flagsmith.hasFeature(
                'should_display_tabs_switcher',
            ) && flagsmith.hasFeature(
                'should_display_btb_component',
            );
        });

        const onTabChange = async (newTab) => {
            currentTab.value = newTab;
        };

        return {
            currentTab,
            onTabChange,
            shouldDisplayBtbComponent,
            shouldDisplayTabsSwitcher,
        };
    },
};
</script>
